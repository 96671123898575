import './App.css';
import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import logo from './img/logo.png';
import info from './img/info.png';
import dessert from './img/dessert.jpeg';
import food from './img/food.jpeg';
import menu from '../src/img/alacarte.pdf'
import takeAway from '../src/img/take-away.pdf'
import restaurant from './img/restaurant.jpg';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';


const Item = styled(Paper)(({ theme }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	border: '1px solid black',
	borderRadius: '5px',
	textAlign: 'center',
	backgroundColor: 'black'
}));

export default function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<Navbar />}>
					</Route>
				</Routes>
			</Router>
			<section className="section1">
				<div className="container">
					<img className='infoImage' src={info} height="400vh" width="auto"></img>
				</div>
			</section>

			<Box sx={{ width: '95%', backgroundColor: 'black' }}>
				<Item>
					<div id="Order">
						<div className="order-now-text">
							<h1 className="title">BESTÄLL HÄR 👇🏻</h1>
							{/*<a href="https://taelo.se/jenny-wok/wmfIw8ACI/menu?fbclid=IwAR0U4PjZXslpohIzaaOyx1EcENnTW71Jfrx_WMKXvcrAltKONKvxN0c3HyM" className="button">Beställ nu</a>*/}
							<div className="flex-parent jc-center">
								<a href="https://taelo.se/jenny-wok/wmfIw8ACI/menu?fbclid=IwAR0U4PjZXslpohIzaaOyx1EcENnTW71Jfrx_WMKXvcrAltKONKvxN0c3HyM" className="button">Take away</a>
								<a href="https://taelo.se/jenny-wok/lXLr6xnAO/menu?fbclid=IwAR0VeR6n7NPW8X3OKOmWTiri3x5cl2HbXCwqffEtrWx_WNXOkbt9JgJZV_E" className="button">Äta här</a>
							</div>
						</div>
					</div>
				</Item>

				<Item>
					<div className="about-image">
						<img
							src={food}
							style={{ width: "85%", height: "85%", objectFit: "cover" }}
						/>
					</div>
				</Item>

				<Item>
					<div className="about-text">
						<h1 className="title">EN ASIATISK MIX</h1>
						<p>Restaurangen serverar en eftertraktad lunchbuffé varje vardag mellan 11:00 och 15:00. Ät så mycket Sushi (även vegetraisk), Japanska, Thailändska, Kinesiska och Vietnameseiska rätter samt sallad och desserter.
						</p>
					</div>
				</Item>

				<Item>
					<div className="about-image">
						<img
							src={dessert}
							style={{ width: "85%", height: "85%", objectFit: "cover" }}
						/>
					</div>
				</Item>

				{/*<Item>
					<div id="Buffe">
						<div className="about-text">
							<h1 className="title">LUNCHBUFFÉ</h1>
							<p>Text om buffé
							</p>
							<h1 className="title">KVÄLLSBUFFÉ</h1>
							<p>Text om buffé
							</p>
							<h1 className="title">HELGBUFFÉ</h1>
							<p>Text om buffé
							</p>
						</div>
					</div>
				</Item>

				<Item>
					<div id="Allergy">
						<div className="about-text">
							<h1 className="title">ALLERGI INFORMATION</h1>
							<p>Text om allergi
							</p>
						</div>
					</div>
				</Item>*/}
				{/*
				<Item>
					<div id="Menu">
						<div className="flex-parent jc-center">
							<a href={menu} className="button">Alacarté meny</a>
							<a href={takeAway} className="button">Take away meny</a>
						</div>
					</div>
				</Item>
*/}

			</Box>
			<section className="map">
				<iframe
					width="100%"
					height="500"
					frameBorder="0"
					style={{ border: 0 }}
					allowFullScreen=""
					aria-hidden="false"
					tabIndex="0"
					referrerPolicy="no-referrer-when-downgrade"
					src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCU-hAEN284trRtuD8-y41Jjzj1J4Lz-KU&q=Jenny+Wok,Ljungby">
				</iframe>
			</section>
			<footer>
				<div id="Contact">
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={3}
							justifyContent="space-evenly"
						>
							<Grid item lg>
								<Item><div className="footer-logo">
									<img src={logo} alt="Logo" width="180px" />
								</div></Item>
							</Grid>
							<Grid item lg>
								<Item><div className="address">
									<h1>Kontakta oss</h1>
									<p>Stora Torget 4, 341 30 Ljungby</p>
									<p>Tel: 0372276841</p>
									<p>Email: info.jennywok@gmail.com</p>
								</div></Item>
							</Grid>
							<Grid item lg>
								<Item><div className="hours">
									<h1>Öppettider</h1>
									<p>Mån-Tor: 11 - 21</p>
									<p>Fre: 11 - 22</p>
									<p>Lör: 11.30 - 22</p>
									<p>Sön: 11.30 - 21</p>
								</div></Item>
							</Grid>
						</Grid>
					</Box>
				</div>
			</footer>
		</div >
	);
}

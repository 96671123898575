import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { theme } from './Theme';
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import menu from '../img/alacarte.pdf';
import takeAway from '../img/take-away.pdf';


const styles = {
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '3em',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2em',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1.25em',
    },
  },
  logo: {
    height: '8em',
    [theme.breakpoints.down('md')]: {
      height: '7em',
    },
    [theme.breakpoints.down('xs')]: {
      height: '5.5em',
    },
  },
  logoContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuIconContainer: {
    marginLeft: 'auto',
    color: 'white',
    '&:hover': {
      opacity: 1,
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: 'black',
  },
};

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static"
      sx={styles.appbar}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} height="80vh"></img>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'flex', xl: 'none' }, justifyContent: "right", alignItems: "center" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'block', xl: 'none' },
              }}
            >
              <MenuItem >
              <a href={"#Order"}>BESTÄLL NU</a>
              </MenuItem>
              <MenuItem>
                <a href={menu}>ALACARTÉ MENY</a>
              </MenuItem>
              <MenuItem>
                <a href={"https://taelo.se/jenny-wok/wmfIw8ACI/menu?fbclid=IwAR0U4PjZXslpohIzaaOyx1EcENnTW71Jfrx_WMKXvcrAltKONKvxN0c3HyM"}>TAKE AWAY MENY</a>
              </MenuItem>
              <MenuItem>
                <a href={"https://taelo.se/jenny-wok/lXLr6xnAO/menu?fbclid=IwAR0VeR6n7NPW8X3OKOmWTiri3x5cl2HbXCwqffEtrWx_WNXOkbt9JgJZV_E"}>BUFFÉ</a>
              </MenuItem>
              <MenuItem>
                <a href={"#Allergy"}>ALLERGIGUIDE</a>
              </MenuItem>
              <MenuItem>
                <a href={"#Contact"}>KONTAKTA OSS</a>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', xl: 'flex' } }}>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Button sx={{
                color: '#8e813d', marginTop: 4, marginBottom: 4, marginRight: 1, marginLeft: 1, fontSize: 20, ':hover': {
                  color: 'white',
                }
              }} label="Order Now" href="#Order">
                BESTÄLL NU
              </Button>
              <Button sx={{
                color: '#8e813d', marginTop: 4, marginBottom: 4, marginRight: 1, marginLeft: 1, fontSize: 20, ':hover': {
                  color: 'white',
                }
              }} label="Menu" href={menu}>
                ALACARTÉ MENY
              </Button>
              <Button sx={{
                color: '#8e813d', marginTop: 4, marginBottom: 4, marginRight: 1, marginLeft: 1, fontSize: 20, ':hover': {
                  color: 'white',
                }
              }} label="Menu" href={"https://taelo.se/jenny-wok/wmfIw8ACI/menu?fbclid=IwAR0U4PjZXslpohIzaaOyx1EcENnTW71Jfrx_WMKXvcrAltKONKvxN0c3HyM"}>
                TAKE AWAY MENY
              </Button>
              <Button sx={{
                color: '#8e813d', marginTop: 4, marginBottom: 4, marginRight: 1, marginLeft: 1, fontSize: 20, ':hover': {
                  color: 'white',
                }
              }} label="Menu" href={"https://taelo.se/jenny-wok/lXLr6xnAO/menu?fbclid=IwAR0VeR6n7NPW8X3OKOmWTiri3x5cl2HbXCwqffEtrWx_WNXOkbt9JgJZV_E"}>
                BUFFÉ
              </Button>
              <Button sx={{
                color: '#8e813d', marginTop: 4, marginBottom: 4, marginRight: 1, marginLeft: 1, fontSize: 20, ':hover': {
                  color: 'white',
                }
              }} label="Menu" href={"#Allergy"}>
                ALLERGIGUIDE
              </Button>
              <Button sx={{
                color: '#8e813d', marginTop: 4, marginBottom: 4, marginRight: 1, marginLeft: 1, fontSize: 20, ':hover': {
                  color: 'white',
                }
              }} label="Menu" href={"#Contact"}>
                KONTAKTA OSS
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
